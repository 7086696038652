.header-container {
  width: 100%;
  background-color: #F4C240; /* Golden Yellow Background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-content {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.logo-section {
  display: flex;
  align-items: center;
}

.logo-section img {
  margin-right: 4px;
}

.logo-text span {
  font-size: 2rem; /* Increased font size */
  color: #FF8C00; /* Dark Orange Text */
  font-family: 'Pacifico', cursive; /* Cursive font */
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  color: #000080; /* Dark Navy Text */
  text-decoration: none;
  font-weight: bold;
}

.nav-link:hover {
  color: #FF4500; /* Bright Red on Hover */
}
