body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  background-color: #F4C240; /* Exact Golden Yellow Background */
  color: #000080; /* Dark Navy Text */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.App {
  display: flex;
  flex-direction: column; /* Stack header and content vertically */
  min-height: 100vh;
  justify-content: space-between; /* Ensures the footer stays at the bottom */
  transition: all 0.3s ease-in-out; /* Smoother transitions */
  padding-top: 20px; /* Add padding to move content down */
}

.header-container {
  width: 100%;
  background-color: #F4C240; /* Golden Yellow Background */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure header is above other content */
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.header-content {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.logo-section {
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.logo-section img {
  margin-right: 8px;
}

.logo-text span {
  font-size: 18px;
  color: #FF8C00; /* Dark Orange Text */
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 20px;
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.nav-link {
  color: #000080; /* Dark Navy Text */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease-in-out;
}

.nav-link:hover {
  color: #FF4500; /* Bright Red on Hover */
}

.landing-page {
  background-color: #FBE18A; /* Lighter Shade for the Box */
  padding: 30px; /* Reduce padding to make the box smaller */
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  margin-top: 80px; /* Add margin to account for fixed header */
  text-align: center;
  width: 90%; /* Reduce width to make the box smaller */
  max-width: 600px; /* Limit the maximum width */
  margin-left: auto; /* Center the box horizontally */
  margin-right: auto; /* Center the box horizontally */
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.taco-truck-image {
  width: 200px; /* Adjust size as needed */
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.app-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #FF8C00; /* Dark Orange Text */
  transition: all 0.3s ease-in-out; /* Smoother transitions */
}

.open-app-button {
  font-size: 1.5rem;
  padding: 15px 30px;
  background-color: #FF8C00; /* Dark Orange Button */
  border: none;
  border-radius: 12px;
  color: #FFFFFF; /* White Text */
  cursor: pointer;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.open-app-button:hover {
  transform: scale(1.05);
  background-color: #FF4500; /* Bright Red on Hover */
}

.taco-rain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent interference with clicks */
  z-index: 9999; /* Ensure it appears on top */
}

.taco {
  position: absolute;
  top: -100px; /* Start above the screen */
  width: 50px; /* Adjust taco size as needed */
  animation: fall 2.14s linear infinite; /* Adjust fall duration as needed */
}

@keyframes fall {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(110vh); /* Fall beyond the bottom of the screen */
  }
}

.page-container {
  margin-top: 20px; /* Adjust this value as needed */
}
